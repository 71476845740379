<template>
    <div id='progress-nav-buttons' class='d-flex justify-content-center'>
        <button class='btn btn-secondary me-2' @click="navigatePrevious" v-show="!isFirst()">Vorige</button>
        <button v-if='isLast()' class='btn btn-primary btn-lg'
          @click="acceptAanmelding()"
          :disabled="!isReadyToSubmit()"
          >
            Afronden
        </button>
         <button v-else class='btn btn-primary btn-lg'
           :disabled="!fullfilled()"
          @click="navigateNext">
            {{ getNextNavigationText() }}
        </button>

    </div>
</template>

<script lang='js'>
import { createToaster } from '@meforma/vue-toaster'

export default {
  methods: {
    fullfilled: function () {
      return this.isStepCompleted(this.$route.name)
    },
    getNextNavigationText: function () {
      if (this.$route.name === 'stapOpmerking') {
        return 'Aanmelding afronden'
      }
      return 'Volgende stap'
    },
    acceptAanmelding: function (e) {
      var self = this
      this.$store.dispatch('aanmelding/accepted').then(function (response) {
        if (response.status === false) {
          createToaster({ position: 'bottom-left', duration: 3000 })
            .error(response.message)
        }
        self.$router.push({ name: response.stap })
      })
    },
    isFirst () {
      return this.$route.name === 'StapPersoon'
    },
    isLast () {
      return this.$route.name === 'StapControle'
    },
    isReadyToSubmit () {
      return this.$store.state.aanmelding.akkoord
    },
    isStepCompleted (step) {
      if (step === 'StapPersoon') {
        return this.$store.state.aanmelding.client !== null
      } else if (step === 'StapActiviteit') {
        return this.$store.state.aanmelding.activiteit !== null
      } else if (step === 'StapCursus') {
        return this.$store.state.aanmelding.cursus !== null
      } else if (step === 'StapMomenten') {
        return this.$store.state.aanmelding.voorkeur !== null && this.$store.state.aanmelding.voorkeur[0] !== null && this.$store.state.aanmelding.voorkeur[1] !== null
      } else if (step === 'StapErvaring') {
        // return this.$store.state.aanmelding.ervaring !== null
        if (this.$store.state.aanmelding.ervaring.eerder === false) {
          return true
        }
        if (this.$store.state.aanmelding.ervaring.afgelopenSeizoen !== null) {
          return true
        }
      } else if (step === 'StapMateriaal') {
        return this.$store.state.aanmelding.materiaal !== null
      } else if (step === 'StapSkikaart') {
        return this.$store.state.aanmelding.skikaart !== null
      } else if (step === 'StapOpmerkingen') {
        return true
      } else if (step === 'StapControle') {
        return this.$store.state.aanmelding.akkoord !== null
      }
    },
    navigateNext () {
      if (this.$route.name === 'StapPersoon' && this.isStepCompleted('StapPersoon')) {
        this.$router.push({ name: 'StapActiviteit' })
      } else if (this.$route.name === 'StapActiviteit' && this.isStepCompleted('StapActiviteit')) {
        this.$router.push({ name: 'StapCursus' })
      } else if (this.$route.name === 'StapCursus') {
        this.$router.push({ name: 'StapMomenten' })
      } else if (this.$route.name === 'StapMomenten') {
        this.$router.push({ name: 'StapErvaring' })
      } else if (this.$route.name === 'StapErvaring') {
        this.$router.push({ name: 'StapMateriaal' })
      } else if (this.$route.name === 'StapMateriaal') {
        this.$router.push({ name: 'StapSkikaart' })
      } else if (this.$route.name === 'StapSkikaart') {
        this.$router.push({ name: 'StapOpmerkingen' })
      } else if (this.$route.name === 'StapOpmerkingen') {
        this.$router.push({ name: 'StapControle' })
      }
    },
    navigatePrevious () {
      if (this.$route.name === 'StapActiviteit') {
        this.$router.push({ name: 'StapPersoon' })
      } else if (this.$route.name === 'StapCursus') {
        this.$router.push({ name: 'StapActiviteit' })
      } else if (this.$route.name === 'StapMomenten') {
        this.$router.push({ name: 'StapCursus' })
      } else if (this.$route.name === 'StapErvaring') {
        this.$router.push({ name: 'StapMomenten' })
      } else if (this.$route.name === 'StapMateriaal') {
        this.$router.push({ name: 'StapErvaring' })
      } else if (this.$route.name === 'StapSkikaart') {
        this.$router.push({ name: 'StapMateriaal' })
      } else if (this.$route.name === 'StapOpmerkingen') {
        this.$router.push({ name: 'StapSkikaart' })
      } else if (this.$route.name === 'StapControle') {
        this.$router.push({ name: 'StapOpmerkingen' })
      }
    },
    checkAll () {
      this.$store.dispatch('aanmelding/checkComplete')
    }
  }
}
</script>
<style scoped>
@media screen and (max-width: 768px) {
    #progress-nav-buttons{
        position: fixed;
        width: 100%;
        bottom: 0;
        background: #fff;
        box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px !important;
        padding: 12px;
        left: 0;
        z-index: 999;
        min-width: 220px;
    }

     /* if iphone, extra padding bottom */
    @media only screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) {
        #progress-nav-buttons{
            padding-bottom: 34px;
        }
    }
}
</style>
