<template>
    <div>
        <navBar title='Aanmelden'></navBar>
        <div class="d-flex d-lg-none position-absolute w-100">
            <button
                @click="annuleren"
                class="btn verwijder-aanmelding">
                Aanmelding annuleren
            </button>
        </div>
       <div class="content-grid">
            <main>
                <router-view v-slot="{ Component }">
                    <transition name="fade" mode="out-in">
                    <component :is="Component" />
                    </transition>
                </router-view>
            </main>
            <aside>
                <button
                    @click="annuleren"
                    class="btn float-start verwijder-aanmelding">
                    Aanmelding annuleren
                </button>
                <aanmelding-summary></aanmelding-summary>
            </aside>
       </div>
       <footer>
            <detached-navigatie></detached-navigatie>
        </footer>
    </div>
</template>

<script>
import navBar from '@/components/Nav.vue'
import detachedNavigatie from '@/views/aanmelding/helpers/DetachedNavigatieStappen.vue'
import aanmeldingSummary from '@/views/aanmelding/helpers/AanmeldingSummary.vue'
export default {
  components: {
    navBar,
    detachedNavigatie,
    aanmeldingSummary
  },
  name: 'Aanmelding',
  methods: {
    annuleren () {
      this.$store.dispatch('aanmelding/reset')
      this.$router.push({ name: 'Landing' })
    }
  }
}
</script>

<style>
    .d-contents{
        display: contents;
    }
    #aanmeld-container{
        padding-bottom: 200px;
    }
    .content-grid{
        display: flex;
        max-width: 100vw;
        overflow-x: hidden;
    }
    main{
        min-height: calc(100vh - 163px);
        padding: 50px 80px 100px;
        flex-grow: 1;
    }
    aside{
        min-width: 350px;
        width: 350px;
        max-width: 350px;
        background: var(--background-light);
    }
    .card-option{
        border: 2px solid  var(--background-light);
        border-radius: 10px;
        padding: 10px 24px;
        font-size: 1.3rem;
        font-weight: 500;
        margin-right: 12px;
        cursor: pointer;
    }

    .card-option.selected{
        background: #fff;
        color: var(--sp2);
        font-weight: bold;
        border: 2px solid var(--sp1);
    }
    .card{
        border: 2px solid #fff;
        cursor: pointer;
        min-height: 100px;
        width: 100%;
        left: 0;
        transition: border 100ms ease-in-out;
    }
    .card.selected{
        border: 2px solid #00a09f;
    }
    .stap-beschrijving{
        font-size: 1.3rem;
        font-weight: bold;
    }
    .stap-number{
        font-weight: bold;
        color: #00a09f;
    }
    .stap-inner{
        width: 750px;
        margin: 25px auto 50px auto;
    }
    .fade-enter-active,
    .fade-leave-active {
        transition: opacity 0.1s ease;
    }
    .fade-enter-from,
    .fade-leave-to {
        opacity: 0;
    }
    .form-topic{
        background: var(--background-light);
        padding: 20px;
        margin-bottom: 1rem;
        border-radius: 20px;
    }
    .verwijder-aanmelding{
        background: var(--sp-dark);
        color: #fff;
        border-radius: 0 !important;
        padding: 12px;
        font-weight: 500;
        width: 100%;
    }

    @media screen and (max-width: 768px) {
        .content-grid{
            display: block;
        }
        aside{
            display: none;
        }
        main{
            padding: 50px 20px 100px;
            text-align: center;
        }

        .card-option{
            width: 100%;
            margin-bottom: 12px;
            margin-right: 0;
        }
    }
</style>
