<template>
    <div class='aanmelding-summary-container position-sticky'>
        <h4>Aanmelding</h4>

        <div v-if='!!aanmelding.client' class="w-100 summary-card animate__animated animate__backInRight"
            @click="navigateTo('StapPersoon')">
            <div class="summary-card-icon-hold">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--! Font Awesome Pro 6.1.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M130.7 313.9C126.5 300.4 137.8 288 151.1 288H364.5C378.7 288 389.9 300.4 385.8 313.9C368.1 368.4 318.2 408 258.2 408C198.2 408 147.5 368.4 130.7 313.9V313.9zM208.4 192C208.4 209.7 194 224 176.4 224C158.7 224 144.4 209.7 144.4 192C144.4 174.3 158.7 160 176.4 160C194 160 208.4 174.3 208.4 192zM304.4 192C304.4 174.3 318.7 160 336.4 160C354 160 368.4 174.3 368.4 192C368.4 209.7 354 224 336.4 224C318.7 224 304.4 209.7 304.4 192zM512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256zM256 48C141.1 48 48 141.1 48 256C48 370.9 141.1 464 256 464C370.9 464 464 370.9 464 256C464 141.1 370.9 48 256 48z"/></svg>
            </div>
            <div>
                <h6>Voor</h6>
                <h5>{{aanmelding.client.firstname}}</h5>
            </div>
        </div>

        <div v-if='!!aanmelding.activiteit' class="w-100 summary-card animate__animated animate__backInRight"
            @click="navigateTo('StapActiviteit')">
            <div class="summary-card-icon-hold">
                <font-awesome-icon v-if='aanmelding.activiteit.id == 1' icon="fa-solid fa-person-skiing" />
                <font-awesome-icon v-else-if='aanmelding.activiteit.id == 2' icon="fa-solid fa-person-snowboarding" />
                <font-awesome-icon v-else icon="fa-solid fa-child-reaching" />
            </div>
            <div>
                <h6>Discipline</h6>
                <h5>{{aanmelding.activiteit.titel}}</h5>
            </div>
        </div>

        <div v-if='!!aanmelding.cursus' class="w-100 summary-card animate__animated animate__backInRight"
            @click="navigateTo('StapCursus')">
            <div class="summary-card-icon-hold">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><!--! Font Awesome Pro 6.1.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M336 64h-53.88C268.9 26.8 233.7 0 192 0S115.1 26.8 101.9 64H48C21.5 64 0 85.48 0 112v352C0 490.5 21.5 512 48 512h288c26.5 0 48-21.48 48-48v-352C384 85.48 362.5 64 336 64zM192 64c17.67 0 32 14.33 32 32s-14.33 32-32 32S160 113.7 160 96S174.3 64 192 64zM282.9 262.8l-88 112c-4.047 5.156-10.02 8.438-16.53 9.062C177.6 383.1 176.8 384 176 384c-5.703 0-11.25-2.031-15.62-5.781l-56-48c-10.06-8.625-11.22-23.78-2.594-33.84c8.609-10.06 23.77-11.22 33.84-2.594l36.98 31.69l72.52-92.28c8.188-10.44 23.3-12.22 33.7-4.062C289.3 237.3 291.1 252.4 282.9 262.8z"/></svg>
            </div>
            <div>
                <h6>Cursus</h6>
                <h5>{{aanmelding.cursus.titel}}</h5>
            </div>
        </div>

        <div v-if='voorkeuren' class="w-100 summary-card animate__animated animate__backInRight"
            @click="navigateTo('StapMomenten')">
            <div class="summary-card-icon-hold">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--! Font Awesome Pro 6.1.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M232 120C232 106.7 242.7 96 256 96C269.3 96 280 106.7 280 120V243.2L365.3 300C376.3 307.4 379.3 322.3 371.1 333.3C364.6 344.3 349.7 347.3 338.7 339.1L242.7 275.1C236 271.5 232 264 232 255.1L232 120zM256 0C397.4 0 512 114.6 512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0zM48 256C48 370.9 141.1 464 256 464C370.9 464 464 370.9 464 256C464 141.1 370.9 48 256 48C141.1 48 48 141.1 48 256z"/></svg>
            </div>
            <div>
                <h6>Voorkeursmoment</h6>
                <h5 id='dagdeel-cap'>{{aanmelding.voorkeur[0].dag.dagDagdeel.dag.description}}{{aanmelding.voorkeur[0].dag.dagDagdeel.dagdeel.periode}}</h5>
            </div>
        </div>

        <div v-if='aanmelding.ervaring.eerder !== null ' class="w-100 summary-card animate__animated animate__backInRight"
            @click="navigateTo('StapErvaring')">
            <div class="summary-card-icon-hold">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><!--! Font Awesome Pro 6.1.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M572.1 82.38C569.5 71.59 559.8 64 548.7 64h-100.8c.2422-12.45 .1078-23.7-.1559-33.02C447.3 13.63 433.2 0 415.8 0H160.2C142.8 0 128.7 13.63 128.2 30.98C127.1 40.3 127.8 51.55 128.1 64H27.26C16.16 64 6.537 71.59 3.912 82.38C3.1 85.78-15.71 167.2 37.07 245.9c37.44 55.82 100.6 95.03 187.5 117.4c18.7 4.805 31.41 22.06 31.41 41.37C256 428.5 236.5 448 212.6 448H208c-26.51 0-47.99 21.49-47.99 48c0 8.836 7.163 16 15.1 16h223.1c8.836 0 15.1-7.164 15.1-16c0-26.51-21.48-48-47.99-48h-4.644c-23.86 0-43.36-19.5-43.36-43.35c0-19.31 12.71-36.57 31.41-41.37c86.96-22.34 150.1-61.55 187.5-117.4C591.7 167.2 572.9 85.78 572.1 82.38zM77.41 219.8C49.47 178.6 47.01 135.7 48.38 112h80.39c5.359 59.62 20.35 131.1 57.67 189.1C137.4 281.6 100.9 254.4 77.41 219.8zM498.6 219.8c-23.44 34.6-59.94 61.75-109 81.22C426.9 243.1 441.9 171.6 447.2 112h80.39C528.1 135.7 526.5 178.7 498.6 219.8z"/></svg>
            </div>
            <div>
                <h6>Ervaring</h6>
                <h5>{{niveau}}</h5>
            </div>
        </div>

        <div v-if='!!aanmelding.materiaal && !!aanmelding.cursus && !aanmelding.cursus.isPriveles' class="w-100 summary-card animate__animated animate__backInRight"
            @click="navigateTo('StapMateriaal')">
            <div class="summary-card-icon-hold">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><!--! Font Awesome Pro 6.1.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M351.1 416H63.99c-17.6 0-31.1 14.4-31.1 31.1l.0026 31.1C31.1 497.6 46.4 512 63.1 512h288c17.6 0 32-14.4 32-31.1l-.0049-31.1C383.1 430.4 369.6 416 351.1 416zM425 206.9c-27.25-22.62-67.5-19-90.13 8.25l-20.88 25L284.4 111.8c-18-77.5-95.38-125.1-172.8-108C34.26 21.63-14.25 98.88 3.754 176.4L64 384h288l81.14-86.1C455.8 269.8 452.1 229.5 425 206.9z"/></svg>            </div>
            <div>
                <h6>Materiaal</h6>
                <h5>{{aanmelding.materiaal.titel}}</h5>
            </div>
        </div>

        <div v-if='!!aanmelding.skikaart && !!aanmelding.cursus && !aanmelding.cursus.isPriveles' class="w-100 summary-card animate__animated animate__backInRight"
            @click="navigateTo('StapSkikaart')">
            <div class="summary-card-icon-hold">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><!--! Font Awesome Pro 6.1.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M512 32C547.3 32 576 60.65 576 96V128H0V96C0 60.65 28.65 32 64 32H512zM576 416C576 451.3 547.3 480 512 480H64C28.65 480 0 451.3 0 416V224H576V416zM112 352C103.2 352 96 359.2 96 368C96 376.8 103.2 384 112 384H176C184.8 384 192 376.8 192 368C192 359.2 184.8 352 176 352H112zM240 384H368C376.8 384 384 376.8 384 368C384 359.2 376.8 352 368 352H240C231.2 352 224 359.2 224 368C224 376.8 231.2 384 240 384z"/></svg>
            </div>
            <div>
                <h6>Vrijskikaart</h6>
                <h5>{{aanmelding.skikaart.skikaart1.split(/[:()]+/)[0]}}</h5>
            </div>
        </div>

        <div v-if='aanmelding.opmerking !== ""' class="w-100 summary-card animate__animated animate__backInRight"
            @click="navigateTo('StapOpmerkingen')">
                <div class="summary-card-icon-hold">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><!--! Font Awesome Pro 6.1.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M208 0C322.9 0 416 78.8 416 176C416 273.2 322.9 352 208 352C189.3 352 171.2 349.7 153.9 345.8C123.3 364.8 79.13 384 24.95 384C14.97 384 5.93 378.1 2.018 368.9C-1.896 359.7-.0074 349.1 6.739 341.9C7.26 341.5 29.38 317.4 45.73 285.9C17.18 255.8 0 217.6 0 176C0 78.8 93.13 0 208 0zM164.6 298.1C179.2 302.3 193.8 304 208 304C296.2 304 368 246.6 368 176C368 105.4 296.2 48 208 48C119.8 48 48 105.4 48 176C48 211.2 65.71 237.2 80.57 252.9L104.1 277.8L88.31 308.1C84.74 314.1 80.73 321.9 76.55 328.5C94.26 323.4 111.7 315.5 128.7 304.1L145.4 294.6L164.6 298.1zM441.6 128.2C552 132.4 640 209.5 640 304C640 345.6 622.8 383.8 594.3 413.9C610.6 445.4 632.7 469.5 633.3 469.9C640 477.1 641.9 487.7 637.1 496.9C634.1 506.1 625 512 615 512C560.9 512 516.7 492.8 486.1 473.8C468.8 477.7 450.7 480 432 480C350 480 279.1 439.8 245.2 381.5C262.5 379.2 279.1 375.3 294.9 369.9C322.9 407.1 373.9 432 432 432C446.2 432 460.8 430.3 475.4 426.1L494.6 422.6L511.3 432.1C528.3 443.5 545.7 451.4 563.5 456.5C559.3 449.9 555.3 442.1 551.7 436.1L535.9 405.8L559.4 380.9C574.3 365.3 592 339.2 592 304C592 237.7 528.7 183.1 447.1 176.6L448 176C448 159.5 445.8 143.5 441.6 128.2H441.6z"/></svg>
                </div>
            <div>
                <h6>Opmerkingen</h6>
                <h5>Genoteerd</h5>
            </div>
        </div>

    </div>
</template>

<script>
import 'animate.css'
var _ = require('lodash')

export default {
  computed: {
    aanmelding () {
      return this.$store.state.aanmelding
    },
    voorkeuren () {
      return !_.isEmpty(this.$store.state.aanmelding.voorkeur[0]) && !_.isEmpty(this.$store.state.aanmelding.voorkeur[1])
    },
    niveau () {
      if (this.$store.state.aanmelding.ervaring.eerder === false) {
        return 'Beginner'
      } else if (this.$store.state.aanmelding.ervaring.afgelopenSeizoen === true) {
        return 'Bekend bij piste'
      }
      return 'Zie profiel'
    }
  },
  methods: {
    navigateTo (route) {
      this.$router.push({ name: route })
    }
  }
}
</script>

<style>
    .aanmelding-summary-container{
        padding: 24px 16px 100px;
        top: 0px;
        max-height: max(90%, calc(100vh - 90px));
        overflow-y: scroll;
    }

    .summary-card{
        cursor: pointer;
        background: #fff;
        border-radius: 15px;
        padding: 12px;
        box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
        display: inline-flex;
        align-items: center;
        margin-bottom: 10px;
    }

    .summary-card h6{
        font-size: 0.8rem;
        margin-bottom: 0;
    }

    .summary-card h5{
        margin-bottom: 0;
    }

    .summary-card svg{
        height: 25px;
        fill: var(--sp1);
        color: var(--sp1);
    }
    .summary-card-icon-hold{
        width: 37px;
    }
    #dagdeel-cap::first-letter{
        text-transform: capitalize;
    }
</style>
