<template>
    <div id='progress-nav-wrapper'>
        <ul id='progress-nav'>
            <li
                v-for="(step, index) in steps"
                :key="index"
                :class="{
                    'active': activeStep === index,
                    'completed': index < activeStep,
                    'not-neccessary': isNecessary(index)
                }"
            >
                <span class="progress-icon">
                    {{index + 1}}
                    <span v-if="isNecessary(index)" class="strikethroug">
                        <!-- slash -->
                        <font-awesome-icon :icon="['fas', 'fa-slash']" />
                    </span>
                </span>
                <span class='progress-step-description'><span class='progress-number'></span>{{step.meta.shortTitle}}</span>
                <div class="progress-bar-nav" id='topic-1-progress'>
                    <span class='inner-bar'></span>
                </div>
            </li>
        </ul>
        <navigatie></navigatie>
    </div>
</template>

<script>
import Navigatie from './Navigatie.vue'
import aanmeldingRouter from '../../../router/aanmelding'
import 'animate.css'

export default {
  name: 'progressBar',
  components: {
    Navigatie
  },
  computed: {
    steps () {
      return aanmeldingRouter
    },
    activeStep () {
      return this.$route.meta.step
    }
  },
  methods: {
    isNecessary (index) {
      return (index === 5 || index === 6) && (!!this.$store.state.aanmelding.cursus && this.$store.state.aanmelding.cursus.isPriveles)
    }
  }
}
</script>

<style>
#progress-nav-wrapper{
    display: flex;
    padding: 0;
    align-items: center;
    border-top: 1px solid var(--background-light);
    margin: 0;
    position: fixed;
    bottom: 0;
    z-index: 10;
    background: #fff;
    right: 0;
    left: 0;
}
#progress-nav{
    display: flex;
    flex-grow: 1;
    padding: 0;
    align-items: center;
    height: 90px;
    margin: 0;
    overflow-x: scroll;
}
@media screen and (max-width: 768px) {
    #progress-nav{
        display: none;
    }

}
#progress-nav li{
    display: flex;
    align-items: center;
    padding-left: 10px;
    flex-grow: 1;
}
#progress-nav .progress-icon{
    background: var(--background-light);
    width: 40px;
    height: 40px;
    border-radius: 50%;
    font-size: 1.3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
}
#progress-nav li.active .progress-icon{
    background: var(--sp1);
    color: #fff;
}
#progress-nav li.completed .progress-icon{
    background: none;
    color: var(--sp1);
}
#progress-nav li.not-neccessary .progress-icon{
   opacity: 0.3;
}
#progress-nav .progress-step-description{
    font-size: 1.3rem;
    font-weight: 700;
    transition: all .5s ease;
    max-width: 0;
    overflow: hidden;
    color: var(--sp1);
    transform: scaleY(0.1);
}
#progress-nav li.active .progress-step-description{
    max-width: 300px;
    color: #000;
    transform: scaleY(1);
}

#progress-nav .progress-number{
    color: var(--vibrant-accent);
    margin: 0 6px;
}
#progress-nav li .progress-bar-nav{
    width: auto;
    height: 2px;
    border-radius: 10px;
    display: flex;
    flex-grow: 1;
    background: var(--ice-b3);
    position: relative;
    margin: 0 5px;
}

#progress-nav li .progress-bar-nav .inner-bar{
    width: 0%;
    transition: width .5s ease;
}

#progress-nav li.completed .progress-bar-nav .inner-bar{
    background: var(--sp1);
    width: 100%;
}

#progress-nav-buttons{
    padding-right: 12px;
}

#progress-nav-buttons .btn{
    box-shadow: 0 6px 8px rgb(198 140 0 / 15%);
    font-weight: 700;
}

.strikethroug{
    margin-left: -20px;
}
</style>
